.social-button {
  margin: 0 4px;
}

.donate-button {
  margin-left: 8px;
}

.main-section {
  min-height: 600px;
  padding-top: 32px;
  padding-bottom: 32px;
}

.footer-social-button {
  margin-top: 8px;
  margin-right: 8px;
}
